import { Organization, OrganizationProps, OrganizationFromApi } from './organization'

export interface UserProps {
  id: number;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isStaff: boolean;
  isAdmin: boolean;
  isReadOnly: boolean;
  organization?: OrganizationProps;
  isUserCreate: boolean;
}

export interface UserFromApi {
  id: number;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  organization: OrganizationFromApi | null;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  is_read_only: boolean;
  is_user_create: boolean;
}

export class User {
  public readonly id: number;
  public readonly email: string;
  public readonly username: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly isActive: boolean;
  public readonly isAdmin: boolean;
  public readonly isStaff: boolean;
  public readonly isReadOnly: boolean;
  public readonly organization?: Organization;
  public readonly isUserCreate: boolean;

  constructor({ id, email, username, firstName, lastName, isActive, isAdmin, isStaff, isReadOnly, organization, isUserCreate }: UserProps) {
    this.id = id;
    this.email = email;
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.isActive = isActive;
    this.isAdmin = isAdmin;
    this.isStaff = isStaff;
    this.isReadOnly = isReadOnly;
    this.organization = organization;
    this.isUserCreate = isUserCreate;
  }

  static fromApi = ({
    id,
    email,
    username,
    first_name: firstName,
    last_name: lastName,
    is_active: isActive,
    is_superuser: isAdmin,
    is_staff: isStaff,
    is_read_only: isReadOnly,
    organization,
    is_user_create: isUserCreate 
  }: UserFromApi) => new User({
      id,
      email,
      username,
      firstName,
      lastName,
      isActive,
      isAdmin,
      isStaff,
      isReadOnly,
      organization: organization ? Organization.fromApi(organization) : undefined,
      isUserCreate
    });
}