import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControl, MenuItem, Modal, Select, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useOrganizations } from "../../../hooks/useOrganizations";
import { api } from "../../../infra/Api";
import { HttpStatus } from "../../../models/HttpStatus";
import { UserFromApi } from "../../../models/User";

export type UserCreateModalProps = {
  isActive: boolean,
  onClose: (isCreated: boolean) => void,
};

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModalContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 50vw;
  padding: 20px;
  max-height: 90vh;
`;

const StyledTitle = styled.div`
  font-size: 20px;
  margin: 0 0 15px;
`;

const StyledSubTitle = styled.div`
  font-size: 14px;
`;

const StyledContentStack = styled(Stack)`
  width: auto;
  padding: 2rem 4rem;
`;

const StyledRowStack = styled(Stack)`
  height: 3em;
`;
StyledRowStack.defaultProps = { spacing: 4, alignItems: 'center', direction: 'row' };

const StyledTitleBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 12em;
`;

const StyledValueBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledErrorsBox = styled(Box)`
  display: flex;
  margin-left: 14em;
  flex-direction: column;
  color: red;
`;

const StyledTextField = styled(TextField)`
`;
StyledTextField.defaultProps = {
  variant: 'standard',
};

const StyledCheckBox = styled(Checkbox)({
  width: "18px",
  height: "18px",
  marginLeft: "12px",
  marginRight: "6px",
});

const StyledFileField = styled(TextField)<{ link?: boolean }>`
  width: 100%;
  ${({ link }) => link ? '.MuiInput-input.Mui-disabled { cursor: pointer; -webkit-text-fill-color: #0056B0; }' : ''};
`;
StyledFileField.defaultProps = {
  variant: 'standard',
  InputLabelProps: {
    shrink: true,
  },
};

const StyledFooter = styled.div`
  text-align: end;
`;

const footerButtonStyle = css({
  width: 'auto',
});

const RequiredBadge = styled.span`
  color: #ffffff;
  background: #cc0000;
  font-size: 0.5em;
  font-weight: bold;
  padding: 0rem 0.25rem;
  border-radius: 0.5em;
  margin-left: 0.5em;"
`;

type Errors = {
  email?: string[];
  password?: string[];
  first_name?: string[];
  last_name?: string[];
  is_active?: string[];
  is_read_only?: string[];
  organization_id?: string[];
}

export const UserCreateModal = ({
  isActive,
  onClose,
}: UserCreateModalProps) => {
  const [isCreating, setIsCreating] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [organizationId, setOrganizationId] = useState('');
  const [errors, setErrors] = useState<Errors>();

  const onChangeEmail = useCallback(e => setEmail(e.target.value), []);
  const onChangePassword = useCallback(e => setPassword(e.target.value), []);
  const onChangePasswordConfirm = useCallback(e => setPasswordConfirm(e.target.value), []);
  const onChangeFirstName = useCallback(e => setFirstName(e.target.value), []);
  const onChangeLastName = useCallback(e => setLastName(e.target.value), []);
  const onChangeIsReadOnly = useCallback(e => setIsReadOnly(e.target.value), []);
  const onChangeOrganizationId = useCallback(e => setOrganizationId(e.target.value), []);

  const [, organizations] = useOrganizations();

  const reset = useCallback(() => {
    setEmail('');
    setPassword('');
    setPasswordConfirm('');
    setFirstName('');
    setLastName('');
    setIsReadOnly(false);
    setOrganizationId('');
    setErrors(undefined);
  }, []);

  const checkCreate = useCallback(() => {
    if (email === '' || password === '' || passwordConfirm === '' || organizationId === '') {
      return false;
    }
    return true;
  }, [email, password, passwordConfirm, organizationId]);

  const createUser = useCallback(async () => {
    setIsCreating(true);
    const url = 'api/v1/admin/user_creator/';

    try {

      if ((password || passwordConfirm) && password !== passwordConfirm) {
        setErrors({
          password: ['パスワードとパスワード(再入力)が異なっています。']
        })
        return;
      }

      const [httpStatus, createdUserFromApi] = await api.post<{
          email: string;
          password: string;
          first_name?: string;
          last_name?: string;
          is_active: boolean;
          is_read_only: boolean;
          organization_id: string;
        }, UserFromApi | Errors>(url, {
          email: email,
          password: password,
          first_name: firstName || '',
          last_name: lastName || '',
          is_active: true,
          is_read_only: isReadOnly,
          organization_id: organizationId,
        });

      if (httpStatus.status === HttpStatus.CREATED) {
        onClose(true);
        reset();
      } else if (httpStatus.status === HttpStatus.BAD_REQUEST) {
        setErrors(createdUserFromApi as Errors);
      }
    } finally {
      setIsCreating(false);
    }
  }, [email, password, passwordConfirm, firstName, lastName, isReadOnly, organizationId, onClose]);



  return (
    <StyledModal
      open={isActive}
      onBackdropClick={() => {
        reset(); 
        onClose(false);}}>
      <StyledModalContainer>
        <StyledTitle>ユーザー追加フォーム</StyledTitle>
        <StyledSubTitle>追加するユーザー情報を入力してください。</StyledSubTitle>
        <StyledContentStack>
          <StyledRowStack>
            <StyledTitleBox>
              メールアドレス<RequiredBadge>必須</RequiredBadge>
            </StyledTitleBox>
            <StyledValueBox>
              <StyledTextField value={email} onChange={onChangeEmail} type="email" placeholder="sample@nealle.com" autoComplete="off" error={!!errors?.email}/>
            </StyledValueBox>
          </StyledRowStack>
          {errors?.email &&
            <StyledRowStack>
              <StyledErrorsBox>
                {errors.email.map((error, index) => (<span key={`error-email-${index}`}>{error}</span>))}
              </StyledErrorsBox>
            </StyledRowStack>
          }
          <StyledRowStack>
            <StyledTitleBox>
              パスワード<RequiredBadge>必須</RequiredBadge>
            </StyledTitleBox>
            <StyledValueBox>
              <StyledTextField value={password} onChange={onChangePassword} type="password" placeholder="password" autoComplete="off" error={!!errors?.password} />
            </StyledValueBox>
          </StyledRowStack>
          {errors?.password &&
            <StyledRowStack>
              <StyledErrorsBox>
              {errors.password.map((error, index) => (<span key={`error-password-${index}`}>{error}</span>))}
              </StyledErrorsBox>
            </StyledRowStack>
          }
          <StyledRowStack style={{ height: 'auto' }}>
            <StyledTitleBox></StyledTitleBox>
            <StyledValueBox>
              <StyledSubTitle style={{padding: '4px 0 5px'}}>
                あなたの他の個人情報と似ているパスワードにはできません。<br />
                パスワードは最低 8 文字以上必要です。<br />
                よく使われるパスワードにはできません。<br />
                数字だけのパスワードにはできません。
              </StyledSubTitle>
            </StyledValueBox>
          </StyledRowStack>
          <StyledRowStack>
            <StyledTitleBox>
              パスワード(再入力)<RequiredBadge>必須</RequiredBadge>
            </StyledTitleBox>
            <StyledValueBox>
              <StyledTextField value={passwordConfirm} onChange={onChangePasswordConfirm} type="password" autoComplete="off" error={!!errors?.password}/>
            </StyledValueBox>
          </StyledRowStack>
          <StyledRowStack style={{ height: 'auto' }}>
            <StyledTitleBox></StyledTitleBox>
            <StyledValueBox>
              <StyledSubTitle style={{padding: '4px 0 5px'}}>
                確認のため、再度パスワードを入力してください。
              </StyledSubTitle>
            </StyledValueBox>
          </StyledRowStack>
          <StyledRowStack>
            <StyledTitleBox>姓</StyledTitleBox>
            <StyledValueBox>
              <StyledTextField value={firstName} onChange={onChangeFirstName} type="text" error={!!errors?.first_name}/>
            </StyledValueBox>
          </StyledRowStack>
          <StyledRowStack>
            <StyledTitleBox>名</StyledTitleBox>
            <StyledValueBox>
              <StyledTextField value={lastName} onChange={onChangeLastName} type="text" error={!!errors?.last_name}/>
            </StyledValueBox>
          </StyledRowStack>
          <StyledRowStack>
            <StyledTitleBox>閲覧権限のみ</StyledTitleBox>
            <StyledCheckBox checked={isReadOnly} onChange={e => onChangeIsReadOnly({ target: { value: e.target.checked } })}/>
          </StyledRowStack>
          <StyledRowStack>
            <StyledTitleBox>
              組織<RequiredBadge>必須</RequiredBadge>
            </StyledTitleBox>
            <FormControl sx={{ minWidth: 1/4 }}>
              <Select
                variant="standard"
                value={organizationId}
                onChange={e => onChangeOrganizationId({ target: { value: e.target.value } })}
                MenuProps={{
                  disableScrollLock: true,
                }}
                error={!!errors?.organization_id}
              >
                {organizations?.map((value, index) => (<MenuItem key={index} value={value.id}>{value.name}</MenuItem>))}
              </Select>
            </FormControl>
          </StyledRowStack>
          {errors?.organization_id &&
            <StyledRowStack>
              <StyledErrorsBox>
                {errors.organization_id.map((error, index) => (<span key={`error-organization_id-${index}`}>{error}</span>))}
              </StyledErrorsBox>
            </StyledRowStack>
          }
        </StyledContentStack>
        <StyledFooter>
          <LoadingButton 
            variant="contained" 
            onClick={createUser}
            css={footerButtonStyle} 
            loading={isCreating} 
            disabled={!checkCreate() || isCreating} 
          >作成</LoadingButton>
        </StyledFooter>
      </StyledModalContainer>
    </StyledModal>
  )
}
