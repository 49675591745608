import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { faArrowRotateLeft, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useCallback, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Navigate, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loggedInUserAtom } from "../../atom/loggedInUserAtom";
import { useApiGet } from "../../hooks/useApiGet";
import { HttpStatus } from "../../models/HttpStatus";
import { UserCreateModal } from "../parts/Account/UserCreateModal";
import { AdminCheck } from "../parts/AdminCheck";
import { Loading } from "../parts/Loading";
import { Paginator } from "../parts/Paginator/Paginator";

const sectionStyle = css({
  width: "100%",
  paddingBottom: "10rem",
  minWidth: "800px",
});

const headStyle = css({
  marginLeft: "3rem",
  marginTop: "2rem",
  fontWeight: "normal"
});

const titleStyle = css({
  fontSize: "12px",
  fontWeight: "bold",
  width: "100px",
  display: "flex",
  alignItems: "center",
  flexWrap: "nowrap"
});

const inputWrapperStyle = css({
  display: "flex",
  justifyContent: "left",
  flexShrink: "initial",
  columnGap: "0.5rem",
  alignItems: "center",
  flexWrap: "nowrap"
});

const filtersStyle = css({
  margin: "1rem", marginBottom: "0", padding: "2rem 0"
})

const menuContainerStyle = css({
  display: "flex",
  justifyContent: "left",
  flexShrink: "initial",
  flexWrap: "wrap",
  marginLeft: "2rem",
  columnGap: "1rem",
  marginBottom: "0.5rem",
  rowGap: "1rem",
});

const searchInputWrapperStyle = css({
  display: "flex",
  background: "white",
  alignItems: "center",
  width: "396px",
  height: "40px",
  border: "solid 1px #DCDCDC",
  borderRadius: "4px",
  paddingLeft: "1rem",
  color: "#222222",
  "&:focus": {
    outline: "2px solid"
  }
});

const searchInputStyle = css({
  display: "block",
  width: "100%",
  height: "90%",
  border: "none",
  "&:focus-visible": {
    outline: "none"
  }
});

const paginationWrapperStyle = css({
  display: "flex",
  marginRight: "2rem",
  justifyContent: "right",
});

const contractListStyle = css({
  background: "white",
  paddingBottom: "2rem"
});

const freeWordWrapperStyle = css({
  display: "flex",
  alignItems: "center"
});

const StyledTable = styled(Table)`
  margin: 2em;
`;

const StyledResetButton = styled(Button)`
  margin-left: 2rem;
`;
StyledResetButton.defaultProps = { variant: "contained" };

const StyledPrimaryButton = styled(Button)`
  margin-left: auto;
`;

export const UserListComp = () => {

  const { page } = useParams();
  const navigate = useNavigate();

  const currentPage = page ? parseInt(page) : 1;

  const [freeWord, setFreeWord] = useState('');

  const user = useRecoilValue(loggedInUserAtom);

  const [httpStatus, users, reFetch] =
    useApiGet<any>(`/api/v1/admin/user_list/?ordering=id&page=${currentPage}&search=${freeWord}`);

  const reload = useCallback((page: number | undefined = undefined) => {
    if (page) {
      reFetch(`/api/v1/admin/user_list/?ordering=id&page=${page}&search=${freeWord}`);
      navigate(`/user-list/${page}`, { replace: false });
    } else {
      setFreeWord('');
      reFetch(`/api/v1/admin/user_list?ordering=id&page=1`);
      navigate('/user-list/1', { replace: false });
    }
  }, [reFetch, navigate, freeWord]);

  const [isActive, setIsActive] = useState(false);

  const openModal = () => {
    setIsActive(true);
  };

  const closeModal = useCallback((isCreated: boolean) => {
    setIsActive(false);
    if (isCreated) reload();
  }, [reload]);

  if (httpStatus.status === HttpStatus.UNAUTHORIZED) {
    return <Navigate to="/login" />
  }

  const userList = users?.results;

  return (
    <section css={sectionStyle}>
       <AdminCheck />
      <h2 css={headStyle}>アカウント管理</h2>
      <article css={filtersStyle}>
        <div css={freeWordWrapperStyle}>
          <div css={menuContainerStyle}>
            <div css={titleStyle}>メールアドレス</div>
            <div css={inputWrapperStyle}>
              <div>
                <div css={searchInputWrapperStyle}>
                  <FontAwesomeIcon css={{ marginRight: "0.5rem" }} color="#DCDCDC" icon={faMagnifyingGlass} />
                  <input
                    placeholder="メールアドレス"
                    defaultValue={freeWord}
                    css={searchInputStyle}
                    type="text"
                    value={freeWord}
                    onChange={e => setFreeWord(e.target.value)}
                    onKeyDown={(e) => {e.key === "Enter" && reload(1);}}
                  />
                </div>
              </div>
            </div>
          </div>
          <StyledResetButton onClick={() => {reload()}} disabled={!freeWord}>
            <FontAwesomeIcon icon={faArrowRotateLeft} />
            条件クリア
          </StyledResetButton>
          {user?.isUserCreate &&
            <StyledPrimaryButton variant="contained" onClick={() => openModal()} disabled={!user?.isUserCreate}>
              新規作成
            </StyledPrimaryButton>
          }
        </div>
      </article>
      <div css={contractListStyle}>
        <div css={{ display: "flex", justifyContent: 'center'}}>
          {users !== undefined ?
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell>姓</TableCell>
                  <TableCell>名</TableCell>
                  <TableCell>組織</TableCell>
                  <TableCell>権限</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList.map((listUser: any, index: number) => (
                  <TableRow key={`listUser-${index}`}>
                    <TableCell><Link to={`/account/${listUser.id}`}>{listUser.email}</Link></TableCell>
                    <TableCell>{listUser.first_name}</TableCell>
                    <TableCell>{listUser.last_name}</TableCell>
                    <TableCell>{listUser.organization?.name}</TableCell>
                    <TableCell>{
                      // listUser.is_superuser ? '管理者' : listUser.is_staff ? 'スタッフ権限' : '一般ユーザー'}</TableCell>
                      listUser.is_superuser ?
                        listUser.is_user_create ?
                          'ユーザー作成権限'
                          : listUser.is_staff ?
                            '管理者' : 'ニーリー権限' 
                        : '一般ユーザー'                  
                    }</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable> : <Loading />
          }
        </div>
        <div>
          <Paginator
            paginationWrapperStyle={paginationWrapperStyle}
            setCurrentPage={(page) => { reload(page); }}
            currentPage={currentPage}
            contracts={users}
          />
        </div>
      </div>
      <UserCreateModal isActive={isActive} onClose={closeModal} />
    </section>
  )
}