import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Button, Card, Checkbox, FormControl, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loggedInUserAtom } from "../../atom/loggedInUserAtom";
import { getOrganizations } from "../../hooks/useOrganizations";
import { api } from "../../infra/Api";
import { HttpStatus } from "../../models/HttpStatus";
import { Organization } from "../../models/organization";
import { User, UserFromApi } from "../../models/User";
import { AdminCheck } from "../parts/AdminCheck";
import { Loading } from "../parts/Loading";
import { UserCreateModal } from "../parts/Account/UserCreateModal";


const sectionStyle = css({
  width: "100%",
  padding: "2rem",
});

const titleStyle = css({
  fontFamily: '"Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif',
  fontWeight: "normal",
  fontSize: "1.4rem"
});

const StyledContentBody = styled.div`
  margin-top: 2rem;
`;

const StyledCard = styled(Card)`
  padding: 2rem;
  width: 50%;
  background-color: white;
`;

const StyledFotter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2rem 0rem;
`;

const StyledRowStack = styled(Stack)`
  height: 3em;
`;
StyledRowStack.defaultProps = { spacing: 4, alignItems: 'center', direction: 'row' };

const StyledTitleBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 10em;
`;

const StyledValueBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledErrosBox = styled(Box)`
  display: flex;
  margin-left: 12em;
  flex-direction: column;
  color: red;
`;

const StyledTextField = styled(TextField)`
`;
StyledTextField.defaultProps = {
  variant: 'standard',
};

const StyledCheckBox = styled(Checkbox)({
  width: "18px",
  height: "18px",
  marginLeft: "12px",
  marginRight: "6px",
});

type Errors = {
  email?: string[];
  password?: string[];
  firstName?: string[];
  lastName?: string[];
  isActive?: boolean[];
  isReadOnly?: boolean[];
  organization?: number[];
}

export const AccountComp = () => {
  const [organizations, setOrganizations] = useState<Organization[] | undefined>();

  const { id } = useParams();

  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserAtom);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editUser, setEditUser] = useState<User | undefined>();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errors, setErrors] = useState<Errors>();
  const [organizationId, setOrganizationId] = useState('');

  const onChangeEmail = useCallback(e => setEditUser(prev => (prev ? { ...prev, email: e.target.value } : undefined)), []);

  const onChangePassword = useCallback(e => setPassword(e.target.value), []);

  const onChangePasswordConfirm = useCallback(e => setPasswordConfirm(e.target.value), []);

  const onChangeFirstName = useCallback(e => setEditUser(prev => (prev ? { ...prev, firstName: e.target.value } : undefined)), []);
  const onChangeLastName = useCallback(e => setEditUser(prev => (prev ? { ...prev, lastName: e.target.value } : undefined)), []);
  const onChangeIsActive = useCallback(e => setEditUser(prev => (prev ? { ...prev, isActive: e.target.value } : undefined)), []);
  const onChangeIsReadOnly = useCallback(e => setEditUser(prev => (prev ? { ...prev, isReadOnly: e.target.value } : undefined)), []);
  const onChangeOrganizationId = useCallback(e => setOrganizationId(e.target.value), []);

  const onPressCancel = useCallback(() => {
    setEditUser(loggedInUser);
    setErrors(undefined);
    setIsEditMode(false);
    setPassword('');
    setPasswordConfirm('');
  }, []);

  const submit = useCallback(async () => {
    const url = loggedInUser?.isAdmin ?
      loggedInUser?.isUserCreate ?
        `/api/v1/admin/user_creator/${id}/` : `/api/v1/admin/user/${id}/`
      : '/api/v1/user/';

    if ((password || passwordConfirm) && password !== passwordConfirm) {
      setErrors({
        password: ['パスワードとパスワード(再入力)が異なっています。']
      })
      return;
    }

    const [httpStatus, updatedUserFromApi] = loggedInUser?.isUserCreate ? await api.patch<{
        email: string;
        password?: string;
        first_name: string;
        last_name: string;
        is_active: boolean;
        is_read_only: boolean;
        organization_id: string;
      }, UserFromApi | Errors>(url, {
        email: editUser?.email || '',
        ...(password ? { password } : {}),
        first_name: editUser?.firstName || '',
        last_name: editUser?.lastName || '',
        is_active: editUser?.isActive || false,
        is_read_only: editUser?.isReadOnly || false,
        organization_id: organizationId || '',
      })
    : await api.patch<{
        email: string;
        password?: string;
      }, UserFromApi | Errors>(url, {
        email: editUser?.email || '',
        ...(password ? { password } : {}),
      });

    if (httpStatus.status === HttpStatus.OK) {
      const updatedUser = User.fromApi(updatedUserFromApi as UserFromApi);
      if (loggedInUser?.id === updatedUser.id) {
        setLoggedInUser(updatedUser);
      }
      setIsEditMode(false);
      setPassword('');
      setPasswordConfirm('');
      setErrors(undefined);
    } else if (httpStatus.status === HttpStatus.BAD_REQUEST) {
      setErrors(updatedUserFromApi as Errors);
    }
  }, [loggedInUser, editUser, password, passwordConfirm, organizationId]);

  const fetchUser = useCallback(async () => {
    const [fetchStatus, editUserFromApi] =  await api.get<UserFromApi>(`/api/v1/admin/user/${id}/`);
    if (fetchStatus.status === HttpStatus.OK && editUserFromApi) {
      setEditUser(User.fromApi(editUserFromApi));
      setOrganizationId(editUserFromApi.organization?.id || '');
    }
  }, []);

  const fetchOrganizations = useCallback(async () => {
    setOrganizations(await getOrganizations());
  }, []);

  const loginUrl = useCallback(() => {
    return organizationId ?
      `${window.location.origin}/${organizations?.find(org => org.id === organizationId)?.securePathId}/login/` :
      `${window.location.origin}/login/`;
  }, [organizations, organizationId]);

  useEffect(() => {
    if (loggedInUser?.isAdmin) {
      fetchUser();
      fetchOrganizations();
    } else {
      setEditUser(loggedInUser);
    }
  }, [loggedInUser, fetchUser]);

  if (!editUser) {
    return <Loading />
  }

  return (
      <section css={sectionStyle}>
        {id && <AdminCheck />}
        <h2 css={titleStyle}>アカウント設定</h2>   
        <StyledContentBody>
          <StyledCard variant="outlined">
            {isEditMode ? (
                <Stack>
                  <StyledRowStack>
                    <StyledTitleBox>メールアドレス</StyledTitleBox>
                    <StyledValueBox>
                      <StyledTextField value={editUser.email} onChange={onChangeEmail} type="email" error={!!errors?.email}/>
                    </StyledValueBox>
                  </StyledRowStack>
                  {errors?.email &&
                    <StyledRowStack>
                      <StyledErrosBox>
                        {errors.email.map((error, index) => (<span key={`error-email-${index}`}>{error}</span>))}
                      </StyledErrosBox>
                    </StyledRowStack>
                  }
                  <StyledRowStack>
                    <StyledTitleBox>パスワード</StyledTitleBox>
                    <StyledValueBox>
                      <StyledTextField value={password} onChange={onChangePassword} type="password" placeholder="変更する場合は入力してください。" autoComplete="off" error={!!errors?.password} />
                    </StyledValueBox>
                  </StyledRowStack>
                  {errors?.password &&
                    <StyledRowStack>
                      <StyledErrosBox>
                      {errors.password.map((error, index) => (<span key={`error-password-${index}`}>{error}</span>))}
                      </StyledErrosBox>
                    </StyledRowStack>
                  }
                  <StyledRowStack>
                    <StyledTitleBox>パスワード(再入力)</StyledTitleBox>
                    <StyledValueBox>
                      <StyledTextField value={passwordConfirm} onChange={onChangePasswordConfirm} type="password" autoComplete="off" error={!!errors?.password}/>
                    </StyledValueBox>
                  </StyledRowStack>
                  {loggedInUser?.isUserCreate &&
                    <>
                      <StyledRowStack>
                        <StyledTitleBox>姓</StyledTitleBox>
                        <StyledValueBox>
                          <StyledTextField value={editUser.firstName} onChange={onChangeFirstName} type="text" error={!!errors?.firstName}/>
                        </StyledValueBox>
                      </StyledRowStack>
                      <StyledRowStack>
                        <StyledTitleBox>名</StyledTitleBox>
                        <StyledValueBox>
                          <StyledTextField value={editUser.lastName} onChange={onChangeLastName} type="text" error={!!errors?.lastName}/>
                        </StyledValueBox>
                      </StyledRowStack>
                      <StyledRowStack>
                        <StyledTitleBox>アカウント状態</StyledTitleBox>
                        <StyledCheckBox checked={editUser.isActive} onChange={e => onChangeIsActive({ target: { value: e.target.checked } })}/>
                      </StyledRowStack>
                      <StyledRowStack>
                        <StyledTitleBox>閲覧権限のみ</StyledTitleBox>
                        <StyledCheckBox checked={editUser.isReadOnly} onChange={e => onChangeIsReadOnly({ target: { value: e.target.checked } })}/>
                      </StyledRowStack>
                      <StyledRowStack>
                        <StyledTitleBox>組織</StyledTitleBox>
                        <FormControl sx={{ minWidth: 1/2 }}>
                          <Select
                            variant="standard"
                            value={organizationId}
                            onChange={e => onChangeOrganizationId({ target: { value: e.target.value } })}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                          >
                            {organizations?.map((value, index) => (<MenuItem key={index} value={value.id}>{value.name}</MenuItem>))}
                          </Select>
                        </FormControl>
                      </StyledRowStack>
                    </>
                  }
                </Stack>
              ): (
                <Stack>
                  <StyledRowStack>
                    <StyledTitleBox>メールアドレス</StyledTitleBox>
                    <StyledValueBox>{editUser.email}</StyledValueBox>
                  </StyledRowStack>
                  <StyledRowStack>
                    <StyledTitleBox>パスワード</StyledTitleBox>
                    <StyledValueBox>非表示</StyledValueBox>
                  </StyledRowStack>
                  {loggedInUser?.isUserCreate &&
                    <>
                       <StyledRowStack>
                        <StyledTitleBox>姓</StyledTitleBox>
                        <StyledValueBox>{editUser.firstName}</StyledValueBox>
                      </StyledRowStack>
                      <StyledRowStack>
                        <StyledTitleBox>名</StyledTitleBox>
                        <StyledValueBox>{editUser.lastName}</StyledValueBox>
                      </StyledRowStack>
                      <StyledRowStack>
                        <StyledTitleBox>アカウント状態</StyledTitleBox>
                        <StyledValueBox>{editUser.isActive ? "有効" : "無効"}</StyledValueBox>
                      </StyledRowStack>
                      <StyledRowStack>
                        <StyledTitleBox>閲覧権限のみ</StyledTitleBox>
                        <StyledValueBox>{editUser.isReadOnly ? "有効" : "無効"}</StyledValueBox>
                      </StyledRowStack>
                      <StyledRowStack>
                        <StyledTitleBox>組織</StyledTitleBox>
                        <StyledValueBox>
                          {editUser.organization ? organizations?.find(org => org.id === organizationId)?.name : ''}
                        </StyledValueBox>
                      </StyledRowStack>
                    </>
                  }
                  {loggedInUser?.isAdmin &&
                    <>
                      <StyledRowStack>
                        <StyledTitleBox>ログインURL</StyledTitleBox>
                        <StyledValueBox>
                          <a href={loginUrl()}>{loginUrl()}</a>
                        </StyledValueBox>
                      </StyledRowStack>
                    </>
                  }
                </Stack>
              )
            }
          </StyledCard>
          <StyledFotter>
            {isEditMode ? (
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" onClick={submit}>保存</Button>
                  <Button onClick={onPressCancel}>キャンセル</Button>
                </Stack>
              ): (
                <Stack direction="row" spacing={2}>
                  {loggedInUser?.isAdmin && <Button onClick={() => window.history.back()}>戻る</Button>}
                  <Button variant="contained" onClick={() => setIsEditMode(true)}>編集</Button>
                </Stack>
              )
            }
          </StyledFotter>
        </StyledContentBody>
      </section>
  )
}